'use client';

import { useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { throttle } from 'lodash';

import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonSize, ButtonType } from '~/v1/components/button/button.interface';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { TEST_ID } from '~/v1/constants/testId';
import { LayoutClient } from '~/v1/system/layout/client/client';

import { AUDIO_PLAYER_ARIA_LABEL, AudioStatus } from './audioPlayer.constants';
import { type AudioPlayerProps } from './audioPlayer.interface';
import styles from './audioPlayer.module.scss';
import { convertTimeToMMSS } from './audioPlayer.utils';

const FADE_OUT_SCROLL = {
  [Breakpoint.LG]: 1600,
  [Breakpoint.MD]: 1200,
  [Breakpoint.SM]: 1000,
};

const AudioPlayerContent: React.FC<AudioPlayerProps> = ({
  className,
  isLarge,
  isFixed,
  reloadLabel = 'Reload audio',
  playerLabel = 'Listen here',
  componentNameAriaLabel,
  onPlay,
  onPause,
  src,
  ...props
}) => {
  const STATUS_ICONS = {
    [AudioStatus.LOADING]: IconType.Loading,
    [AudioStatus.ERROR]: IconType.Reload,
    [AudioStatus.LOADED]: IconType.Headphone,
    [AudioStatus.PLAYING]: IconType.Pause,
    [AudioStatus.PAUSED]: isLarge ? IconType.Play : IconType.Headphone,
  };
  const [isFaded, setIsFaded] = useState(false);
  const breakpoint = useBreakpoint();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    const audioElementObject = audioElement.current;
    return () => {
      window.removeEventListener('scroll', handleScroll);
      audioElementObject.pause();
      audioElementObject.currentTime = 0;
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (isFixed) {
      // TODO: Remove this coercion. `useBreakpoint` should return a Breakpoint.
      const isScrolledOverTreshold = scrollY > FADE_OUT_SCROLL[breakpoint as Breakpoint];
      const stateIsDifferent = isScrolledOverTreshold !== isFaded;
      if (stateIsDifferent) {
        setIsFaded(isScrolledOverTreshold);
      }
    }
  }, [breakpoint, isFaded, isFixed, scrollY]);

  useEffect(() => {
    handleScroll();
  }, [scrollY, isFixed, handleScroll]);

  const [audioStatus, setAudioStatus] = useState<AudioStatus>(AudioStatus.LOADING);
  const [currentTime, setCurrentTime] = useState(0);
  const audioElement = useRef(new Audio(src));
  const duration = audioElement.current.duration;

  const isPlaying = audioStatus === AudioStatus.PLAYING;
  const isPaused = audioStatus === AudioStatus.PAUSED;
  const isLoading = audioStatus === AudioStatus.LOADING;
  const isError = audioStatus === AudioStatus.ERROR;
  const isLoaded = audioStatus === AudioStatus.LOADED;
  const notLoadingOrError = !isLoading && !isError;
  const isPlayingOrPaused = isPlaying || isPaused;

  const handleTime = throttle(() => {
    const currentTime = Math.trunc(audioElement.current.currentTime);
    setCurrentTime(currentTime);
  }, 1000);

  useEffect(() => {
    audioElement.current.src = src || '';
  }, [src]);

  useEffect(() => {
    const audioElementRef = audioElement.current;
    audioElementRef.onloadedmetadata = () => {
      setAudioStatus(AudioStatus.LOADED);
    };

    audioElementRef.onerror = () => {
      setAudioStatus(AudioStatus.ERROR);
    };

    audioElementRef.onplaying = () => {
      setAudioStatus(AudioStatus.PLAYING);
    };

    audioElementRef.onpause = () => {
      setAudioStatus(AudioStatus.PAUSED);
    };

    audioElementRef.onloadstart = () => {
      setAudioStatus(AudioStatus.LOADING);
    };

    audioElementRef.onwaiting = () => {
      setAudioStatus(AudioStatus.LOADING);
    };

    if (isLarge) {
      audioElementRef.ontimeupdate = handleTime;
      audioElementRef.onended = () => {
        setAudioStatus(AudioStatus.LOADED);
      };
    }
    return () => {
      audioElementRef.onloadedmetadata = null;
      audioElementRef.onerror = null;
      audioElementRef.onplaying = null;
      audioElementRef.onpause = null;
      audioElementRef.ontimeupdate = null;
      audioElementRef.onended = null;
      audioElementRef.onloadstart = null;
      audioElementRef.onwaiting = null;
    };
  }, [audioElement, handleTime, isLarge]);

  const handleAudioClick = () => {
    if (isPlaying) {
      audioElement.current.pause();
      onPause && onPause();
    } else if (isPaused || isLoaded) {
      audioElement.current.play();
      onPlay && onPlay();
    } else if (isError) {
      setAudioStatus(AudioStatus.LOADING);
      audioElement.current.load();
    }
  };

  const buttonClasses = cx(styles.audioPlayer, styles[`audioPlayer-${audioStatus}`], className, {
    [styles.audioPlayerLarge]: isLarge,
    [styles.audioPlayerFixed]: isFixed,
    [styles.audioPlayerFaded]: isFaded && isFixed && !isPlayingOrPaused,
  });

  const defaultAudioPlayerType = isPlaying ? ButtonType.Secondary : ButtonType.Primary;
  const audioPlayerType = isLarge ? ButtonType.Primary : defaultAudioPlayerType;

  const labelClasses = cx(styles.label, {
    [styles.labelVisible]: isLoaded,
  });

  const largePlayerIconClasses = cx(styles.buttonIcon, {
    [styles.buttonIconLoading]: isLarge && isLoading,
  });

  const timeClasses = cx(styles.time, 'buttonLabelTypography', {
    [styles.timeVisible]: !isLoading,
  });

  const time = convertTimeToMMSS(isLoaded ? duration || 0 : currentTime);
  const shouldUseAriaLabel = !isLarge || isLoading;

  return (
    <Button
      {...props}
      onClick={handleAudioClick}
      className={buttonClasses}
      size={isLarge ? ButtonSize.Medium : ButtonSize.Icon}
      aria-label={
        shouldUseAriaLabel
          ? `${AUDIO_PLAYER_ARIA_LABEL[audioStatus]}${
              componentNameAriaLabel ? ` ${componentNameAriaLabel}` : ''
            } audio`
          : undefined
      }
      type={audioPlayerType}
      mode={ButtonMode.Light}
      data-test-id={TEST_ID.AUDIO_PLAYER}
    >
      {!isLarge && <Icon className={styles.buttonIcon} type={STATUS_ICONS[audioStatus]} />}

      {isLarge && (
        <div className={styles.playerContent}>
          <Icon className={largePlayerIconClasses} type={STATUS_ICONS[audioStatus]} />
          <div className={styles.playerLabelWrapper} aria-hidden={!notLoadingOrError}>
            <p className={labelClasses}> {playerLabel}</p>
            {isError && <p className={styles.reloadLabel}>{reloadLabel}</p>}
            {isPlayingOrPaused && (
              <div className={styles.progressBarWrapper}>
                <div className={styles.progressBar}>
                  <div
                    className={styles.progressBarCompleted}
                    style={{
                      width: `${(currentTime / duration) * 100}%`,
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <p
            className={timeClasses}
            aria-hidden={!notLoadingOrError || isPlaying}
            aria-label={`Total time ${time}`}
          >
            {time}
          </p>
        </div>
      )}
    </Button>
  );
};

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ ...props }) => {
  return (
    <LayoutClient>
      <AudioPlayerContent {...props} />
    </LayoutClient>
  );
};
